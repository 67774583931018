import "./App.css";
import React, { useEffect, useState } from "react";
// import {} from ""
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdAdd, MdManageSearch } from "react-icons/md";
import { BsCloudDownload } from "react-icons/bs";
import iconLinkedin from "./img/linkedin.png";
import iconInstagram from "./img/instagram.png";
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";
import { dbFirestore } from "./services/firebaseConfig";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";

function App() {
  const [jogosDoUsuario, setJogosDoUsuario] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSorteio, setLoadingSorteio] = useState(false);
  const [mostrarAjuda, setMostrarAjuda] = useState(false);
  const [concurso, setConcurso] = useState("");
  const [dezenasEncontradas, setDezenasEncontradas] = useState();
  const [conferencia, setConferencia] = useState([]);
  const [inputSorteados, setInputSorteados] = useState("");
  const [inputNovoJogo, setInputNovoJogo] = useState("");
  const [ultimosConcursos, setUltimosConcursos] = useState([]);

  useEffect(() => {
    loadStorage();
    buscarConcursos();
  }, []);

  function loadStorage() {
    const dadosDoUsuario = localStorage.getItem("dadosDoUsuario");

    if (dadosDoUsuario) {
      setJogosDoUsuario(JSON.parse(dadosDoUsuario));
      setLoading(false);
    }

    setLoading(false);
  }

  const conferir = () => {
    
    let _inputSorteados = inputSorteados.replaceAll(' ', '')

    let sorteados = _inputSorteados.split(",");

    console.log(sorteados)

    for (let i = 0; i < sorteados.length; i++) {
      // limpar elementos possivelmente vazios ou indefinidos
      if (isNaN(parseInt(sorteados[i]))) {
        sorteados.splice(i, 1);
      }
    }

    if (sorteados.length == 6) {
      let conferencia = [];
      for (let i = 0; i < jogosDoUsuario.length; i++) {
        let acertos = 0;
        let acertados = [];
        for (let j = 0; j < sorteados.length; j++) {
          let qtdNumeros = jogosDoUsuario[i].numeros.filter(
            (item) => parseInt(item) == parseInt(sorteados[j])
          );
          acertos += qtdNumeros.length;
          if (qtdNumeros.length > 0) {
            acertados.push(parseInt(sorteados[j]));
          } else {
            acertados.push("");
          }
        }
        conferencia.push({
          numeros: jogosDoUsuario[i].numeros,
          acertos: acertos,
          sorteados: sorteados,
          acertados,
        });
      }

      setConferencia(conferencia);
    } else {
      toast.error(
        `Você preencheu ${sorteados.length} números, a quantidade de números sorteados deve ser 6.`
      );
    }
  };

  const adicionarJogo = () => {
    let todosJogos = jogosDoUsuario;
    let arrayJogo = inputNovoJogo.split(",");

    for (let i = 0; i < arrayJogo.length; i++) {
      // limpar elementos possivelmente vazios ou indefinidos
      if (isNaN(parseInt(arrayJogo[i]))) {
        arrayJogo.splice(i, 1);
      }
    }

    if (arrayJogo.length >= 6) {
      todosJogos.push({ numeros: arrayJogo });
      localStorage.setItem("dadosDoUsuario", JSON.stringify(todosJogos));
      toast.success("Jogo adicionado com sucesso!");
      setInputNovoJogo("");
    } else {
      toast.error(
        `O minimo de números para um jogo é 6. Você digitou ${arrayJogo.length} numeros.`
      );
    }

    loadStorage();
  };

  const limparDados = () => {
    localStorage.removeItem("dadosDoUsuario");
    setJogosDoUsuario([]);
    setConferencia([]);
  };

  const deletarJogo = (index) => {
    let todosJogos = jogosDoUsuario;
    todosJogos.splice(index, 1);
    localStorage.setItem("dadosDoUsuario", JSON.stringify(todosJogos));

    loadStorage();
    toast.success("Jogo deletado com sucesso!");
  };

  const buscarConcursos = async () => {
    setLoadingSorteio(true);
    try {
      const routeAccounts = query(
        collection(dbFirestore, "loterias", "megasena", "jogos"),
        orderBy("numeroConcurso", "desc"),
        limit(10)
      );

      onSnapshot(routeAccounts, (querySnapshot) => {
        const listConcursos = [];
        querySnapshot.forEach((doc) => {
          let d = doc.data();
          listConcursos.push({
            concurso: doc.id,
            dezenas: d.dezenas,
            dataConcurso: d.dataConcurso,
          });
        });

        setUltimosConcursos([{}, ...listConcursos]);
      });

      setLoadingSorteio(false);
    } catch {
      setLoadingSorteio(false);
    }
  };

  const preencherResultado = (index) => {
    // console.log(ultimosConcursos[index]);
    if (index != 0) {
      let d = ultimosConcursos[index].dezenas;
      let dezenas = `${d[0]},${d[1]},${d[2]},${d[3]},${d[4]},${d[5]}`;
      setInputSorteados(dezenas);
      setDezenasEncontradas(dezenas);
      setConcurso(
        `${ultimosConcursos[index].concurso} (${ultimosConcursos[index].dataConcurso})`
      );
    } else {
      setInputSorteados("");
      setDezenasEncontradas("");
    }
  };
  return (
    <>
      <ToastContainer autoClose={5000} />
      <div className="App">
        <div className="viewbackground">
          <h2>Anote e confira aqui seus jogos</h2>
          <h1 style={{ fontStyle: "italic", fontSize: "3rem" }}>Mega Sena</h1>
          <h2>
            Dia 31 é a mega da virada com o premio de{" "}
            <span style={{ textDecoration: "underline" }}>
              R$ 500.000.000,00
            </span>
          </h2>
          
          <h2 style={{ marginTop: "20%" }}>Meus Jogos:</h2>

          {conferencia.length > 0 ? (
            
            
            <ul>
              {conferencia?.map((jogo) => {
                return (
                  <li
                    style={{
                      transform:
                        parseInt(jogo.acertos) > 3 ? `scale(1.02)` : `scale(1)`,
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[0]) )
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[0]},
                      </span>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[1]))
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[1]},
                      </span>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[2]))
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[2]},
                      </span>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[3]))
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[3]},
                      </span>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[4]))
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[4]},
                      </span>
                      <span
                        style={{
                          color: jogo.acertados.includes(parseInt(jogo.numeros[5]))
                            ? "#28b856"
                            : "#ff0000",
                        }}
                      >
                        {" "}
                        {jogo.numeros[5]}
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          color:
                            parseInt(jogo.acertos) > 3 ? "#28b856" : "#000",
                        }}
                      >
                        Acertos: {jogo.acertos}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <>
              {/* <h2 style={{ marginTop: "20%" }}>Meus Jogos:</h2> */}

              <span style={{ color: "#28b856", fontStyle: "italic" }}>
                *digite os números separados por vírgula.
              </span>
              <input
                value={inputNovoJogo}
                type="text"
                placeholder="Adicione aqui seus jogos"
                onChange={(e) => setInputNovoJogo(e.target.value)}
                className="input-novos-jogos"
              />
              <button
                className="btn-add-novo-jogo"
                onClick={() => adicionarJogo()}
              >
                Adicionar jogo <MdAdd color="#ffffff" size={30} />
              </button>

              <ul className="meus-jogos">
                {jogosDoUsuario?.map((jogo, index) => {
                  return (
                    <li key={index} style={{ justifyContent: "center" }}>
                      <div>
                        <span> {jogo.numeros[0]},</span>
                        <span> {jogo.numeros[1]},</span>
                        <span> {jogo.numeros[2]},</span>
                        <span> {jogo.numeros[3]},</span>
                        <span> {jogo.numeros[4]},</span>
                        <span> {jogo.numeros[5]}</span>
                      </div>
                      <button
                        className="btn-delete"
                        onClick={() => deletarJogo(index)}
                      >
                        <RiDeleteBin6Line color="#ff0000" size={20} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          <button
            type="button"
            className="btn-limpar-dados"
            onClick={() => {
              limparDados();
            }}
          >
            Apagar todos os jogos
          </button>







          <h2 style={{ marginTop: "20%" }}>Confira seus jogos aqui:</h2>



          <span style={{ color: "#28b856", fontStyle: "italic" }}>
            *digite os números separados por vírgula.
          </span>
          <input
            value={inputSorteados}
            type="text"
            placeholder="Numeros sorteados"
            onChange={(e) => setInputSorteados(e.target.value)}
            className="input-sorteados"
          />
          {dezenasEncontradas && (
            <div className="sorteio-encontrado">
              <i>
                Concurso selecionado:{" "}
                <i style={{ fontWeight: "bold" }}>{concurso}</i>
              </i>
              <i>
                Dezenas:{" "}
                <i style={{ fontWeight: "bold" }}>{dezenasEncontradas}</i>
              </i>
            </div>
          )}
          <div className="area-btn">
            <select onChange={(e) => preencherResultado(e.target.value)}>
              {ultimosConcursos.map((item, index) => {
                return (
                  <option key={index} value={index}>
                    {item?.concurso
                      ? `Concurso ${item?.concurso} - Data: ${item?.dataConcurso}`
                      : "Escolha um concurso para auto-preencher"}
                  </option>
                );
              })}
            </select>
            <button className="btn-conferir" onClick={() => conferir()}>
              <span>Conferir</span>
              <MdManageSearch color="#ffffff" size={25} />
            </button>
          </div>
          
          <div className="developer">
            <div>
              <span>Desenvolvido por: </span>
              <span>
                <a href="https://devholz.com/" target="_blank">
                  devholz.com
                </a>
              </span>
              <div>
                <a>
                  <img src={iconLinkedin} />
                </a>
                <a>
                  <img src={iconInstagram} />
                </a>
              </div>
            </div>

            <button
              className="btn-ajuda"
              onClick={() => setMostrarAjuda(!mostrarAjuda)}
            >
              Ajuda e Sobre
            </button>
          </div>
          {mostrarAjuda && (
            <div className="ajuda-sobre">
              <h3>Sobre:</h3>
              <p>
                Esta página foi desenvolvida com o intuito de auxiliar aos
                apostadores a conferência dos resultados do jogo de loteria Mega
                Sena, em especial, a Mega da Virada.
              </p>
              <p>
                É importante destacar, que os dados inseridos nesta página pelos
                visitantes são apenas armazenados na cache do seu navegador,
                desta forma os dados estão apenas em seu dispositivo.
              </p>

              <h3>Ajuda:</h3>
              <p>
                1. Para adicionar seus jogos, basta digitar os números separados
                por vírgula na caixa de texto "Adicione aqui seus jogos", abaixo
                de "Meus Jogos".
              </p>
              <p>
                2. Os jogos vão ficar disponíveis em uma lista logo abaixo da
                caixa de texto onde é adicionado os jogos.
              </p>
              <p>3. Para realizar a conferência dos jogos:</p>
              <p>
                3.1 Opção 1: Adicionar na caixa de texto "Números sorteados" as dezenas sorteadas e clicar
                em "Conferir".
              </p>
              <p>
                3.2 Opção 2: Selecionar o concurso disponível na aplicação para
                que aconteça o auto-preenchimento, então clique em "Conferir". (serão listados os últimos 10 concursos.)
              </p>
              <p>4. Após clicar em conferir, ficará disponível a lista com todos os jogos e a quantidade de acerto de cada jogo.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
